import { Route, Routes } from 'react-router';
import { BrowserRouter, Navigate } from 'react-router-dom';

import CertificateValidatePage from './pages/certificate-validate.page';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import NotFoundPage from './pages/not-found-404.page';
import HomePage from './pages/home.page';
import CertificateFeliuas2024FormPage from './pages/certificate-form-feliuas2024.page';
import CertificateFeliuas2025FormPage from './pages/certificate-form-feliuas2025.page';
import CertificateFiucPage from './pages/certificate-form-fiuc2024.page';

function App() {
  return (
    <BrowserRouter basename="/">
      <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/feliuas-2024" exact element={<CertificateFeliuas2024FormPage />} />
          <Route path="/feliuas-2025" exact element={<CertificateFeliuas2025FormPage />} />
          <Route path="/fiuc-2024" exact element={<CertificateFiucPage />} />
          <Route path="/validate/:id" exact element={<CertificateValidatePage />} />
          <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
